/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AboutCampaignBack } from '../models/AboutCampaignBack';
import type { Body_modify_campaign_background_picture } from '../models/Body_modify_campaign_background_picture';
import type { Body_modify_campaign_picture } from '../models/Body_modify_campaign_picture';
import type { CampaignBackDetails } from '../models/CampaignBackDetails';
import type { CampaignBackOverview } from '../models/CampaignBackOverview';
import type { CampaignThemeBack } from '../models/CampaignThemeBack';
import type { CreateCampaignData } from '../models/CreateCampaignData';
import type { CreateCategoryData } from '../models/CreateCategoryData';
import type { CreateFaqData } from '../models/CreateFaqData';
import type { CreateNewsData } from '../models/CreateNewsData';
import type { DataCampaignBack } from '../models/DataCampaignBack';
import type { DescriptionCampaignBack } from '../models/DescriptionCampaignBack';
import type { FaqBack } from '../models/FaqBack';
import type { FaqBackCategory } from '../models/FaqBackCategory';
import type { ModifyCampaignBackNetwork } from '../models/ModifyCampaignBackNetwork';
import type { ModifyCampaignBackPaypal } from '../models/ModifyCampaignBackPaypal';
import type { NewsBack } from '../models/NewsBack';
import type { PackBackOption } from '../models/PackBackOption';
import type { server__db__schemas_back__CampaignPaypal } from '../models/server__db__schemas_back__CampaignPaypal';
import type { ThemeBack } from '../models/ThemeBack';
import type { ValueBackPosition } from '../models/ValueBackPosition';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CampaignBackService {

    /**
     * Get Campaigns Back
     * @returns CampaignBackOverview Successful Response
     * @throws ApiError
     */
    public static getCampaignsBack(): CancelablePromise<Array<CampaignBackOverview>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/campaigns',
        });
    }

    /**
     * Get Campaign Back
     * @param campaignId
     * @returns CampaignBackDetails Successful Response
     * @throws ApiError
     */
    public static getCampaignBack(
        campaignId: string,
    ): CancelablePromise<CampaignBackDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/campaign',
            query: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Campaign
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyCampaign(
        requestBody: DataCampaignBack,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Campaign
     * @param requestBody
     * @returns CampaignBackOverview Successful Response
     * @throws ApiError
     */
    public static createCampaign(
        requestBody: CreateCampaignData,
    ): CancelablePromise<CampaignBackOverview> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/create',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Themes
     * @returns ThemeBack Successful Response
     * @throws ApiError
     */
    public static getThemes(): CancelablePromise<Array<ThemeBack>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/campaign/theme',
        });
    }

    /**
     * Modify Campaign Theme
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyCampaignTheme(
        requestBody: CampaignThemeBack,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/theme',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Campaign Picture
     * @param formData
     * @returns string Successful Response
     * @throws ApiError
     */
    public static modifyCampaignPicture(
        formData: Body_modify_campaign_picture,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/picture',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Campaign Background Picture
     * @param formData
     * @returns string Successful Response
     * @throws ApiError
     */
    public static modifyCampaignBackgroundPicture(
        formData: Body_modify_campaign_background_picture,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/background-picture',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Campaign Description
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyCampaignDescription(
        requestBody: DescriptionCampaignBack,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/description',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Campaign About
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyCampaignAbout(
        requestBody: AboutCampaignBack,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/about',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Value Position
     * @param requestBody
     * @returns PackBackOption Successful Response
     * @throws ApiError
     */
    public static modifyValuePosition(
        requestBody: ValueBackPosition,
    ): CancelablePromise<PackBackOption> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/value/positon',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Campaign Network
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static modifyCampaignNetwork(
        requestBody: ModifyCampaignBackNetwork,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/network',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Faq Campaign Back
     * @param campaignId
     * @returns FaqBackCategory Successful Response
     * @throws ApiError
     */
    public static getFaqCampaignBack(
        campaignId: string,
    ): CancelablePromise<Array<FaqBackCategory>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/campaign/faq',
            query: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Faq
     * @param requestBody
     * @returns FaqBack Successful Response
     * @throws ApiError
     */
    public static modifyFaq(
        requestBody: CreateFaqData,
    ): CancelablePromise<FaqBack> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/faq',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify Faq Category
     * @param requestBody
     * @returns FaqBackCategory Successful Response
     * @throws ApiError
     */
    public static modifyFaqCategory(
        requestBody: CreateCategoryData,
    ): CancelablePromise<FaqBackCategory> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/category',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get News Campaign Back
     * @param campaignId
     * @returns NewsBack Successful Response
     * @throws ApiError
     */
    public static getNewsCampaignBack(
        campaignId: string,
    ): CancelablePromise<Array<NewsBack>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/campaign/news',
            query: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Modify News
     * @param requestBody
     * @returns NewsBack Successful Response
     * @throws ApiError
     */
    public static modifyNews(
        requestBody: CreateNewsData,
    ): CancelablePromise<NewsBack> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/news',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Ship Campaign
     * @param campaignId
     * @returns CampaignBackDetails Successful Response
     * @throws ApiError
     */
    public static shipCampaign(
        campaignId: string,
    ): CancelablePromise<CampaignBackDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/ship',
            query: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Deliver Campaign
     * @param campaignId
     * @returns CampaignBackDetails Successful Response
     * @throws ApiError
     */
    public static deliverCampaign(
        campaignId: string,
    ): CancelablePromise<CampaignBackDetails> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/deliver',
            query: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Campaign Paypal
     * @param campaignId
     * @returns server__db__schemas_back__CampaignPaypal Successful Response
     * @throws ApiError
     */
    public static getCampaignPaypal(
        campaignId: string,
    ): CancelablePromise<server__db__schemas_back__CampaignPaypal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/back/campaign/paypal',
            query: {
                'campaign_id': campaignId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Paypal
     * @param requestBody
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static updatePaypal(
        requestBody: ModifyCampaignBackPaypal,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/back/campaign/paypal',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

import {ApiError, BacketCheckError, BacketCheckValid, Basket, BasketItem} from '../types';
import {BasketDelivery, DeliveryAddress} from '../types';
import {useMutation, useQuery} from 'react-query';

import {BASKET_GET} from './keys';
import {BasketService} from '../types/services/BasketService';

export function useAddBasket() {
    return useMutation<Basket, ApiError, BasketItem>((item: BasketItem) =>
        BasketService.addBasket(item)
    );
}

export function useModifyBasket() {
    return useMutation<Basket, ApiError, BasketItem>((item: BasketItem) =>
        BasketService.modifyBasket(item)
    );
}

export function useModifyBasketDelivery() {
    return useMutation<Basket, ApiError, BasketDelivery>((item: BasketDelivery) =>
        BasketService.modifyBasketDelivery(item)
    );
}

export function useModifyBasketDeliveryAddress() {
    return useMutation<Basket, ApiError, DeliveryAddress>((item: DeliveryAddress) =>
        BasketService.modifyBasketDeliveryAddress(item)
    );
}

export function useGetBasket(enabled: boolean) {
    return useQuery(BASKET_GET, BasketService.getBasket, {
        enabled: enabled,
        refetchOnMount: true,
    });
}

export function useEmptyBasket() {
    return useMutation<boolean, ApiError, string | void>(BasketService.emptyBasket);
}

export function useCheckBasket() {
    return useMutation<BacketCheckError | BacketCheckValid, ApiError, string>(
        (campaignId: string) => BasketService.checkBasket(campaignId)
    );
}

import {OpenAPI} from '../types';

export const URL_SERVER = process.env.URL_SERVER ?? 'http://localhost:8001';
export const URL_SERVER_INTERNAL = process.env.URL_SERVER_INTERNAL ?? 'http://localhost:8001';
export const URL_CLIENT = process.env.URL_CLIENT ?? 'http://localhost:3000';

export const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY ?? 'AIzaSyDP8mjWaxRstus-NthHa7zbgXrMrOl2Ui4',
    authDomain: process.env.FIREBASE_AUTH_DOMAIN ?? 'crowdfunding-dev-95cf7.firebaseapp.com',
    projectId: process.env.FIREBASE_PROJECT_ID ?? 'crowdfunding-dev-95cf7',
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET ?? 'crowdfunding-dev-95cf7.appspot.com',
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID ?? '305911732563',
    appId: process.env.FIREBASE_APP_ID ?? '1:305911732563:web:76fdaaa04f8f16d082a9a6',
};

export const firebaseFile =
    process.env.FIREBASE_FILE ??
    'crowdfunding-dev-95cf7-firebase-adminsdk-ccc51-a4ca99bd2f.json';

export const gtm = process.env.GTM ?? 'GTM-W2S3B28';

OpenAPI.BASE = URL_SERVER;

export const IS_DARK_THEME = 'IS_DARK_THEME';

export function getDark() {
    if (typeof window !== 'undefined') {
        // Perform localStorage action
        const isDarkThemeLocal = localStorage.getItem(IS_DARK_THEME);
        const prefersDark =
            window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        if (isDarkThemeLocal) {
            return isDarkThemeLocal == 'true';
        } else if (prefersDark) {
            return true;
        }
    }
}

// Use ngrok to simulate secure host in localhost (localhost share button purpose)
const SECURE_HOST = 'https://747c-2a01-cb19-69d-a500-209b-eaa6-57da-6b80.ngrok.io';

export function getShareButtonHost() {
    if (URL_CLIENT.indexOf('https') != -1) {
        return URL_CLIENT;
    } else {
        return SECURE_HOST;
    }
}

export const emojis = [
    '😀',
    '😃',
    '😄',
    '😁',
    '😆',
    '😊',
    '😇',
    '🙂',
    '😍',
    '🥰',
    '😘',
    '😙',
    '🤩',
    '🥳',
    '😎',
    '👍',
];

export const PARTICIPATIONS_PAGE_SIZE = 10;

export const STORAGE =
    'https://storage.sbg.cloud.ovh.net/v1/AUTH_857d29cf94c54215b0da86e11c623b12/crowdfunding';

export function getDomain() {
    if (URL_CLIENT.indexOf('https://') != -1) {
        return URL_CLIENT.replace('https://', '');
    } else {
        return URL_CLIENT.replace('http://', '');
    }
}

export const SLOGANS = [
    'Plutôt 2 fois KUNE',
    'Le cochon qui vous veut du bien',
    'Votre tirelire vous remerciera',
    'Mange vos pièces depuis 2023',
    'Votre précommande est entre de bonnes pattes',
    'Fabricateur de pièces roses de père en fils',
    "Accepte les pièces d'or",
    'Précommandez-les tous',
    'Vaut son poids en or',
    'Ne fait Kune bouchée de vos pièces',
    'Deux pièces vaut mieux Kune',
    'Appelez un kune un Kune',
    'Faire contre mauvaise fortune, bon Kune',
    'Et si l’argent faisait le bonheur ?',
    'La parole est d’argent...',
    'Par les créateurs pour les créateurs',
    'Précommandez en mode facile',
];

/* = 640 / 360 */
export const RATIO_IMAGE = 1920 / 1080;

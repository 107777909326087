/** The basket is either stored in DB (if the user is connected) or stored in the user's local storage if he is not connected.
 * When the user connects we get save the current local storage basket in the DB and delete it from the local storage.
 * If the DB basket wasn't empty at that moment, we replace it by the local storage basket (unless the local storage basket is empty).
 * This is a choice and could be changed by not emptying the DB basket before saving the local items.
 */
import {Basket, BasketItem, DeliveryAddress} from '../types';
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {
    useAddBasket,
    useEmptyBasket,
    useGetBasket,
    useModifyBasket,
    useModifyBasketDelivery,
    useModifyBasketDeliveryAddress,
} from '../queries/basketQueries';

import {BASKET_GET} from '../queries/keys';
import {useQueryClient} from 'react-query';
import {useUser} from './UserProvider';

export function BasketProvider(props: {children: React.ReactNode}) {
    const queryClient = useQueryClient();
    const {dbUser} = useUser();
    const {data: dbBasket, refetch: refetchDbBasket, isLoading} = useGetBasket(false);
    const [localBasket, setLocalBasket] = useState<Basket>();
    const {mutateAsync: modifyBasketInDb} = useModifyBasket();
    const {mutateAsync: addBasketInDb} = useAddBasket();
    const {mutateAsync: emptyBasketInDb} = useEmptyBasket();
    const {mutateAsync: modifyBasketDeliveryInDb} = useModifyBasketDelivery();
    const {mutateAsync: modifyBasketDeliveryAddress} = useModifyBasketDeliveryAddress();

    const addDbItem = useCallback(
        async (basketItem: BasketItem) => {
            const newDbBasket = await addBasketInDb(basketItem);
            queryClient.setQueryData(BASKET_GET, newDbBasket);
            return newDbBasket;
        },
        [addBasketInDb, queryClient]
    );

    const updateDbItem = useCallback(
        async (basketItem: BasketItem) => {
            const newDbBasket = await modifyBasketInDb(basketItem);
            queryClient.setQueryData(BASKET_GET, newDbBasket);
            return newDbBasket;
        },
        [modifyBasketInDb, queryClient]
    );

    const updateDbDelivery = useCallback(
        async (deliveryPrice: number, deliveryCountry: string) => {
            const basketData = await modifyBasketDeliveryInDb({
                delivery_price: deliveryPrice,
                delivery_country: deliveryCountry,
            });
            queryClient.setQueryData(BASKET_GET, basketData);
            return basketData;
        },
        [modifyBasketDeliveryInDb, queryClient]
    );

    const updateDbDeliveryAddress = useCallback(
        async (deliveryAddress: DeliveryAddress) => {
            const basketData = await modifyBasketDeliveryAddress(deliveryAddress);
            queryClient.setQueryData(BASKET_GET, basketData);
            return basketData;
        },
        [modifyBasketDeliveryAddress, queryClient]
    );

    const emptyDbCampaignBasket = useCallback(
        async (campaignId: string) => {
            const success = await emptyBasketInDb(campaignId);
            if (success) {
                const otherCampaignsItems = dbBasket?.items.filter(
                    (item) => item.campaign_id != campaignId
                );
                queryClient.setQueryData<Basket>(BASKET_GET, {items: otherCampaignsItems});
            }
        },
        [dbBasket?.items, emptyBasketInDb, queryClient]
    );

    const emptyDbBasket = useCallback(async () => {
        const success = await emptyBasketInDb();
        if (success) {
            queryClient.setQueryData<Basket>(BASKET_GET, {items: []});
        }
    }, [emptyBasketInDb, queryClient]);

    const [isSavingBasket, setIsSavingBasket] = useState(false);
    const saveBasketToDb = useCallback(async () => {
        if (!isSavingBasket) {
            setIsSavingBasket(true);
            const basket = getLocalStorageBasket();
            deleteLocalStorageBasket();
            setLocalBasket((basket) => ({...basket, items: []}));
            await emptyDbBasket(); // It makes a bit more sense, otherwise we would mix both the "old" user basket and the new one
            for (const item of basket) {
                await updateDbItem(item);
            }
            setIsSavingBasket(false);
        }
    }, [emptyDbBasket, isSavingBasket, updateDbItem]);

    const addBasket = useCallback(
        async (basketItem: BasketItem) => {
            if (dbUser) {
                const newBasket = await addDbItem(basketItem);
                return newBasket?.items?.length ?? 0;
            } else {
                const items = updateLocalQuantity(basketItem, (item) => item.quantity + 1);
                setLocalBasket((basket) => ({...basket, items}));
                return items.length ?? 0;
            }
        },
        [addDbItem, dbUser]
    );

    const modifyBasket = useCallback(
        async (basketItem: BasketItem) => {
            if (dbUser) {
                console.log('Updating DATABASE basket', basketItem);
                await updateDbItem(basketItem);
            } else {
                if (basketItem.quantity == 0) {
                    const items = removeLocalBasket(basketItem.pack_id);
                    setLocalBasket((basket) => ({...basket, items}));
                } else {
                    const items = updateLocalQuantity(basketItem, (_) => basketItem.quantity);
                    setLocalBasket((basket) => ({...basket, items}));
                }
            }
        },
        [dbUser, updateDbItem]
    );

    const emptyBasket = useCallback(
        async (campaignId: string) => {
            if (dbUser) {
                await emptyDbCampaignBasket(campaignId);
            } else {
                const items = emptyLocalBasket(campaignId);
                setLocalBasket((basket) => ({...basket, items}));
            }
        },
        [dbUser, emptyDbCampaignBasket]
    );

    const updateBasket = useCallback(
        async (items: BasketItem[]) => {
            if (dbUser) {
                queryClient.setQueryData(BASKET_GET, {items: items});
            } else {
                setLocalStorageBasket(items);
                setLocalBasket((basket) => ({...basket, items}));
            }
        },
        [dbUser, queryClient]
    );

    const updateBasketDelivery = useCallback(
        async (deliveryPrice: number, deliveryCountry: string) => {
            if (dbUser) {
                const basketData = await updateDbDelivery(deliveryPrice, deliveryCountry);
                setLocalBasket(basketData);
            } else {
                setLocalBasket((basket) => {
                    return {
                        ...basket,
                        delivery_price: deliveryPrice,
                        delivery_country: deliveryCountry,
                    };
                });
            }
        },
        [dbUser, updateDbDelivery]
    );

    const updateBasketDeliveryAddress = useCallback(
        async (deliveryAddress: DeliveryAddress) => {
            if (dbUser) {
                const basketData = await updateDbDeliveryAddress(deliveryAddress);
                setLocalBasket(basketData);
            } else {
                setLocalBasket((basket) => {
                    return {
                        ...basket,
                        delivery_address: deliveryAddress,
                    };
                });
            }
        },
        [dbUser, updateDbDeliveryAddress]
    );

    const isDbBasketEmpty = (dbBasket?.items?.length ?? 0) == 0;
    const isLocalBasketEmpty = (localBasket?.items?.length ?? 0) == 0;

    useEffect(() => {
        setLocalBasket((basket) => ({...basket, items: getLocalStorageBasket()}));
    }, []);

    useEffect(() => {
        if (dbUser) {
            refetchDbBasket();
        }
    }, [dbUser, refetchDbBasket]);

    useEffect(() => {
        if (
            dbUser &&
            !isLocalBasketEmpty &&
            !isLoading &&
            dbBasket != null &&
            isDbBasketEmpty
        ) {
            saveBasketToDb();
        }
    }, [
        isLoading,
        dbBasket,
        dbUser,
        saveBasketToDb,
        localBasket,
        isDbBasketEmpty,
        isLocalBasketEmpty,
    ]);

    const basket = isDbBasketEmpty ? localBasket : dbBasket;
    const value = useMemo(
        () => ({
            basket,
            addBasket,
            modifyBasket,
            emptyBasket,
            updateBasket,
            updateBasketDelivery,
            updateBasketDeliveryAddress,
            isLoading,
        }),
        [
            basket,
            addBasket,
            modifyBasket,
            emptyBasket,
            updateBasket,
            updateBasketDelivery,
            updateBasketDeliveryAddress,
            isLoading,
        ]
    );

    return <BasketContext.Provider value={value} {...props} />;
}

const DEFAULT_CONTEXT_VALUE: BasketContextInfo = {
    addBasket: async (basketItem: BasketItem) => {
        return 0;
    },
    modifyBasket: async (basketItem: BasketItem) => {},
    emptyBasket: async (campaignId: string) => {},
    updateBasketDelivery: async (deliveryPrice: number, deliveryCountry: string) => {},
    updateBasketDeliveryAddress: async (deliveryAddress: DeliveryAddress) => {},
    updateBasket: async (items: BasketItem[]) => {},
    basket: null,
    isLoading: false,
};

export type BasketContextInfo = {
    addBasket: (basketItem: BasketItem) => Promise<number>;
    modifyBasket: (basketItem: BasketItem) => Promise<void>;
    emptyBasket: (campaignId: string) => Promise<void>;
    updateBasketDelivery: (deliveryPrice: number, deliveryCountry: string) => Promise<void>;
    updateBasketDeliveryAddress: (deliveryAddress: DeliveryAddress) => Promise<void>;
    updateBasket: (items: BasketItem[]) => Promise<void>;
    basket: Basket | null | undefined;
    isLoading: boolean;
};

export const BasketContext = React.createContext(DEFAULT_CONTEXT_VALUE);

export function useBasket() {
    return useContext(BasketContext);
}

const LOCAL_BASKET_KEY = 'LOCAL_BASKET_KEY';

function fillQuantityAndOptions(item: BasketItem) {
    const now = new Date(Date.now()).toLocaleDateString();
    const jsonOptions = item.options.map((option) => ({
        pack_option_id: option.pack_option_id,
        pack_option_value_id: option.pack_option_value_id,
    }));
    return {
        ...item,
        quantity: 1,
        options: jsonOptions,
        updated: now,
        added: now,
    };
}

function getLocalStorageBasket() {
    const json = localStorage.getItem(LOCAL_BASKET_KEY);
    if (json) {
        return JSON.parse(json) as BasketItem[];
    }
    return [] as BasketItem[];
}

function setLocalStorageBasket(items: BasketItem[]) {
    localStorage.setItem(LOCAL_BASKET_KEY, JSON.stringify(items));
}

function deleteLocalStorageBasket() {
    localStorage.removeItem(LOCAL_BASKET_KEY);
}

function updateLocalQuantity(newItem: BasketItem, updateValue: (item: BasketItem) => number) {
    let basket = getLocalStorageBasket();
    if (!basket) {
        const items = [fillQuantityAndOptions(newItem)];
        setLocalStorageBasket(items);
        return items;
    } else {
        let isNewItem = true; // false -> same item already in basket, we just increase its quantity
        for (const item of basket) {
            if (item.pack_id == newItem.pack_id) {
                let sameOption = true;
                for (const basketOption of newItem.options) {
                    for (const localOption of item.options) {
                        if (
                            localOption.pack_option_value_id !=
                            basketOption.pack_option_value_id
                        ) {
                            sameOption = false;
                        }
                    }
                }
                if (sameOption) {
                    item.quantity = updateValue(item);
                    // TODO : updated date
                    isNewItem = false;
                }
            }
        }
        if (isNewItem) {
            basket.push(fillQuantityAndOptions(newItem));
        }
        setLocalStorageBasket(basket);
        return basket;
    }
}

function removeLocalBasket(packId: string) {
    let localItems = getLocalStorageBasket();

    if (!localItems) {
        return [];
    } else {
        let idx = 0;
        for (const localItem of localItems) {
            if (localItem.pack_id == packId) {
                localItems.splice(idx, 1);
            }
            idx++;
        }

        setLocalStorageBasket(localItems);
        return localItems;
    }
}

function emptyLocalBasket(campaignId: string) {
    const localBasket = getLocalStorageBasket();
    const otherCampaignsItems = localBasket.filter((item) => item.campaign_id != campaignId);
    setLocalStorageBasket(otherCampaignsItems);
    return otherCampaignsItems;
}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Author } from '../models/Author';
import type { AuthorUrl } from '../models/AuthorUrl';
import type { CampaignDetails } from '../models/CampaignDetails';
import type { CampaignLike } from '../models/CampaignLike';
import type { CampaignList } from '../models/CampaignList';
import type { CampaignOverview } from '../models/CampaignOverview';
import type { CampaignPack } from '../models/CampaignPack';
import type { CampaignSeo } from '../models/CampaignSeo';
import type { Page_Participation_ } from '../models/Page_Participation_';
import type { ParticipationFilter } from '../models/ParticipationFilter';
import type { server__db__schemas__CampaignPaypal } from '../models/server__db__schemas__CampaignPaypal';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CampaignService {

    /**
     * Get Campaign
     * @returns CampaignOverview Successful Response
     * @throws ApiError
     */
    public static getCampaign(): CancelablePromise<Array<CampaignOverview>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaign',
        });
    }

    /**
     * Get Campaign Detail
     * @param url
     * @returns CampaignDetails Successful Response
     * @throws ApiError
     */
    public static getCampaignDetail(
        url: string,
    ): CancelablePromise<CampaignDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaign/detail',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Campaign Pack
     * @param url
     * @returns CampaignPack Successful Response
     * @throws ApiError
     */
    public static getCampaignPack(
        url: string,
    ): CancelablePromise<CampaignPack> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaign/pack',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Campaign Overview
     * @param url
     * @returns CampaignOverview Successful Response
     * @throws ApiError
     */
    public static getCampaignOverview(
        url: string,
    ): CancelablePromise<CampaignOverview> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaign/overview',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Author By Url
     * @param url
     * @returns Author Successful Response
     * @throws ApiError
     */
    public static getAuthorByUrl(
        url: string,
    ): CancelablePromise<Author> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/author/url',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Author Campaigns
     * @param authorId
     * @returns CampaignOverview Successful Response
     * @throws ApiError
     */
    public static getAuthorCampaigns(
        authorId: string,
    ): CancelablePromise<Array<CampaignOverview>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/author/campaigns',
            query: {
                'author_id': authorId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Author
     * @returns AuthorUrl Successful Response
     * @throws ApiError
     */
    public static getAuthor(): CancelablePromise<Array<AuthorUrl>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/author/list',
        });
    }

    /**
     * Get Campaign List
     * @returns CampaignList Successful Response
     * @throws ApiError
     */
    public static getCampaignList(): CancelablePromise<Array<CampaignList>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaign/list',
        });
    }

    /**
     * Get Campaign Seo
     * @param url
     * @returns CampaignSeo Successful Response
     * @throws ApiError
     */
    public static getCampaignSeo(
        url: string,
    ): CancelablePromise<CampaignSeo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaign/seo',
            query: {
                'url': url,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Campaign Like
     * @returns CampaignLike Successful Response
     * @throws ApiError
     */
    public static getCampaignLike(): CancelablePromise<Array<CampaignLike>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaign/like',
        });
    }

    /**
     * Like Campaign
     * @param campaignUrl
     * @returns number Successful Response
     * @throws ApiError
     */
    public static likeCampaign(
        campaignUrl: string,
    ): CancelablePromise<number> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaign/like',
            query: {
                'campaign_url': campaignUrl,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Campaign Participation
     * @param requestBody
     * @param page
     * @param size
     * @returns Page_Participation_ Successful Response
     * @throws ApiError
     */
    public static getCampaignParticipation(
        requestBody: ParticipationFilter,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_Participation_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/campaign/participation',
            query: {
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Campaign Paypal
     * @param campaignUrl
     * @returns server__db__schemas__CampaignPaypal Successful Response
     * @throws ApiError
     */
    public static getCampaignPaypal(
        campaignUrl: string,
    ): CancelablePromise<server__db__schemas__CampaignPaypal> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/campaign/paypal',
            query: {
                'campaign_url': campaignUrl,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
